import type { Swiper } from 'swiper';
import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule } from '../utils/helpers';

const swipers: Swiper[] = [];

const getElements = () => ({
  swiperElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--services .swiper',
  ),
});

const cleanupServicesSwipers = () => {
  while (swipers.length > 0) {
    swipers.pop()?.destroy(true, true);
  }
};

const setupServicesSwipers = async () => {
  const { swiperElements } = getElements();
  if (!swiperElements) return;

  const { Swiper } = await import('swiper');

  swiperElements.forEach((swiperElement) => {
    swipers.push(
      new Swiper(swiperElement, {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
      }),
    );
  });
};

const servicesSwiperBreakpointChecker = async () => {
  if (BREAKPOINT_MEDIA_QUERIES.lg.matches) {
    cleanupServicesSwipers();
  } else {
    await setupServicesSwipers();
  }
};

export default defineModule(
  async () => {
    await servicesSwiperBreakpointChecker();
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      servicesSwiperBreakpointChecker,
    );
  },
  () => {
    cleanupServicesSwipers();
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      servicesSwiperBreakpointChecker,
    );
  },
);
