import { setCursor } from '../components/cursor';
import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule } from '../utils/helpers';

const sliders: ReturnType<typeof createSlider>[] = [];

const getElements = () => ({
  headerSliderElements: document.querySelectorAll<HTMLElement>(
    '.content-block--header-slider .header-slider__slider',
  ),
});

const createSlider = (el: HTMLElement) => {
  let currentSlide = 0;
  const contentBlockElement = el.closest<HTMLElement>(
    '.content-block--header-slider',
  );

  const onSliderClick = (e: MouseEvent) => {
    if (!BREAKPOINT_MEDIA_QUERIES.md.matches) return;

    const slideToLeft = e.clientX < document.documentElement.clientWidth / 2;
    if (slideToLeft && currentSlide !== 0) {
      currentSlide -= 1;
    } else if (!slideToLeft && currentSlide !== el.childElementCount - 1) {
      currentSlide += 1;
    }

    contentBlockElement?.style.setProperty(
      '--current-slide',
      `${currentSlide}`,
    );
  };

  const onContentBlockMouseLeave = () => setCursor('hidden');

  const onMousEventSetCorrectCursor = (e: MouseEvent) => {
    const slideToLeft = e.clientX < document.documentElement.clientWidth / 2;

    if (
      slideToLeft &&
      currentSlide !== 0 &&
      BREAKPOINT_MEDIA_QUERIES.md.matches
    ) {
      contentBlockElement?.classList.add('md:cursor-none');
      setCursor('arrow-left');
    } else if (
      !slideToLeft &&
      currentSlide !== el.childElementCount - 1 &&
      BREAKPOINT_MEDIA_QUERIES.md.matches
    ) {
      contentBlockElement?.classList.add('md:cursor-none');
      setCursor('arrow-right');
    } else {
      contentBlockElement?.classList.remove('md:cursor-none');
      setCursor('hidden');
    }
  };

  contentBlockElement?.addEventListener(
    'mouseenter',
    onMousEventSetCorrectCursor,
  );
  contentBlockElement?.addEventListener(
    'mousemove',
    onMousEventSetCorrectCursor,
  );
  contentBlockElement?.addEventListener('click', onSliderClick);
  contentBlockElement?.addEventListener('mouseleave', onContentBlockMouseLeave);

  return {
    destroy: () => {
      contentBlockElement?.removeEventListener(
        'mouseenter',
        onMousEventSetCorrectCursor,
      );
      contentBlockElement?.removeEventListener(
        'mousemove',
        onMousEventSetCorrectCursor,
      );
      contentBlockElement?.removeEventListener('click', onSliderClick);
      contentBlockElement?.removeEventListener(
        'mouseleave',
        onContentBlockMouseLeave,
      );
    },
  };
};

export default defineModule(
  () => {
    const { headerSliderElements } = getElements();

    headerSliderElements.forEach((headerSliderElement) => {
      sliders.push(createSlider(headerSliderElement));
    });
  },
  () => {
    while (sliders.length > 0) {
      sliders.pop()?.destroy();
    }
  },
);
