import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { createElement, defineModule } from '../utils/helpers';

type CursorType = 'hidden' | 'arrow-left' | 'arrow-right';

const CURSOR_SIZE = 70;

const cursorElement = createElement('div', {
  className: 'cursor cursor--hidden',
});
cursorElement.style.setProperty('--size', `${CURSOR_SIZE}px`);
document.body.appendChild(cursorElement);

export const setCursor = (type: CursorType) => {
  if (BREAKPOINT_MEDIA_QUERIES.touch.matches) {
    return;
  }

  cursorElement.setAttribute('class', `cursor cursor--${type}`);
};

const cursorMoveListener = (event: MouseEvent) => {
  cursorElement.style.top = `${event.clientY}px`;
  cursorElement.style.left = `${event.clientX}px`;
  cursorElement.style.transform = `translate(${CURSOR_SIZE / 2})`;
};

const cursorMediaBreakpointListener = () => {
  if (
    BREAKPOINT_MEDIA_QUERIES['no-touch'].matches &&
    BREAKPOINT_MEDIA_QUERIES.md.matches
  ) {
    window.addEventListener('mousemove', cursorMoveListener);
  } else {
    window.removeEventListener('mousemove', cursorMoveListener);
  }
};

export default defineModule(
  () => {
    [BREAKPOINT_MEDIA_QUERIES['no-touch'], BREAKPOINT_MEDIA_QUERIES.md].forEach(
      (mediaQuery) =>
        mediaQuery.addEventListener('change', cursorMediaBreakpointListener),
    );
    cursorMediaBreakpointListener();
  },
  () => {
    [BREAKPOINT_MEDIA_QUERIES['no-touch'], BREAKPOINT_MEDIA_QUERIES.md].forEach(
      (mediaQuery) =>
        mediaQuery.removeEventListener('change', cursorMediaBreakpointListener),
    );
    setCursor('hidden');
  },
);
