import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule } from '../utils/helpers';
import { Overlay, toggleOverlay } from '../utils/overlays';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('nav.navbar__nav'),
  navbarButtonElement: document.querySelector<HTMLButtonElement>(
    'nav.navbar__nav button[aria-expanded][aria-controls]',
  ),
});

const menuToggle = () => {
  const { navbarButtonElement } = getElements();
  if (!navbarButtonElement) return;

  const expanded = navbarButtonElement.getAttribute('aria-expanded') !== 'true';
  navbarButtonElement.setAttribute('aria-expanded', expanded.toString());
  toggleOverlay(Overlay.MENU, expanded);
};

const menuMediaChecker = () => {
  const { navbarButtonElement } = getElements();
  if (!navbarButtonElement) return;

  navbarButtonElement.setAttribute('aria-expanded', 'false');
  toggleOverlay(Overlay.MENU, false);
};

export default defineModule(
  () => {
    const { navbarButtonElement, navbarElement } = getElements();
    if (!navbarButtonElement || !navbarElement) return;

    navbarButtonElement.addEventListener('click', menuToggle);

    BREAKPOINT_MEDIA_QUERIES.md.addEventListener('change', menuMediaChecker);
  },
  () => {
    const { navbarButtonElement, navbarElement } = getElements();
    if (!navbarButtonElement || !navbarElement) return;

    navbarButtonElement.removeEventListener('click', menuToggle);

    BREAKPOINT_MEDIA_QUERIES.md.removeEventListener('change', menuMediaChecker);
  },
);
