import lottie from 'lottie-web';
import logoAnimationData from '../animations/logo.json';

import { defineModule } from '../utils/helpers';

const animationsMap: Record<string, object> = {
  logo: logoAnimationData,
};

export default defineModule(
  () => {
    const lottieElements =
      document.querySelectorAll<HTMLElement>('[data-lottie]');
    lottieElements.forEach((lottieElement) => {
      const animationName = lottieElement.getAttribute('data-lottie');
      if (!animationName || !animationsMap[animationName]) return;

      lottie.loadAnimation({
        container: lottieElement,
        loop: false,
        animationData: animationsMap[animationName],
      });
    });
  },
  () => {
    lottie.destroy();
  },
);
