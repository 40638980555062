export enum Overlay {
  MENU,
}

const OPENED_OVERLAYS: Overlay[] = [];

export const toggleOverlay = (overlay: Overlay, force?: boolean) => {
  force = force ?? !OPENED_OVERLAYS.includes(overlay);

  const existingIndex = OPENED_OVERLAYS.findIndex(
    (_overlay) => _overlay === overlay,
  );
  if ((force && existingIndex !== -1) || (!force && existingIndex === -1)) {
    return;
  }

  if (force) {
    if (!OPENED_OVERLAYS.length) {
      document.body.classList.toggle('overflow-y-hidden', true);
    }
    OPENED_OVERLAYS.push(overlay);
  } else {
    OPENED_OVERLAYS.splice(existingIndex, 1);
    if (!OPENED_OVERLAYS.length) {
      document.body.classList.toggle('overflow-y-hidden', false);
    }
  }
};

export const hasOpenOverlay = (overlay: Overlay) =>
  OPENED_OVERLAYS.includes(overlay);

export const hasAnyOpenOverlay = () => !!OPENED_OVERLAYS.length;
