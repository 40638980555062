import debounce from 'lodash-es/debounce';
import { defineModule } from '../utils/helpers';
import {
  isLineClamping,
  toggleLineClamp,
  updateLineClamp,
} from '../utils/line-clamp';

const getElements = () => ({
  uspCardElements: document.querySelectorAll<HTMLElement>(
    '.content-block--unique-selling-point-cards .usp-card',
  ),
});

const toggleUspCard = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const lineClampElements = e.currentTarget
    .closest<HTMLElement>('.content-block')
    ?.querySelectorAll<HTMLElement>('.line-clamp');

  const currentLineClampElement = e.currentTarget
    .closest<HTMLElement>('.usp-card')
    ?.querySelector<HTMLElement>('.line-clamp');

  lineClampElements?.forEach((lineClampElement) => {
    toggleLineClamp(
      lineClampElement,
      lineClampElement === currentLineClampElement ? undefined : false,
    );
  });
};

const onWindowResize = () => {
  const { uspCardElements } = getElements();

  uspCardElements.forEach((uspCardElement) => {
    const lineClampElement =
      uspCardElement.querySelector<HTMLElement>('.line-clamp');
    if (!lineClampElement) return;

    updateLineClamp(lineClampElement);

    const uspCardTogglerElement =
      uspCardElement.querySelector<HTMLElement>('.usp-card__toggler');

    uspCardTogglerElement?.classList.toggle(
      'hidden',
      !isLineClamping(lineClampElement),
    );
  });
};

let timeout: NodeJS.Timeout | number;
const debouncedOnWindowResize = debounce(() => {
  clearTimeout(timeout);

  onWindowResize();

  timeout = setTimeout(() => {
    onWindowResize();
  }, 250);
}, 100);

export default defineModule(
  () => {
    const { uspCardElements } = getElements();

    uspCardElements.forEach((uspCardElement) => {
      const uspCardTogglerElement =
        uspCardElement.querySelector<HTMLButtonElement>('.usp-card__toggler');
      if (!uspCardTogglerElement) return;

      uspCardTogglerElement.addEventListener('click', toggleUspCard);
    });

    window.addEventListener('resize', debouncedOnWindowResize);
    onWindowResize();
  },
  () => {
    const { uspCardElements } = getElements();

    uspCardElements.forEach((uspCardElement) => {
      const uspCardTogglerElement =
        uspCardElement.querySelector<HTMLButtonElement>('.usp-card__toggler');
      if (!uspCardTogglerElement) return;

      uspCardTogglerElement.removeEventListener('click', toggleUspCard);
    });

    clearTimeout(timeout);
    window.removeEventListener('resize', debouncedOnWindowResize);
  },
);
