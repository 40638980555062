import type { Swiper } from 'swiper';
import { defineModule } from '../utils/helpers';

const swipers: Swiper[] = [];

const getElements = () => ({
  swiperElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--references-slider .swiper',
  ),
});

export default defineModule(
  async () => {
    const { swiperElements } = getElements();
    if (!swiperElements.length) return;

    const [{ Swiper }, { Pagination, Navigation }] = await Promise.all([
      import('swiper'),
      import('swiper/modules'),
    ]);

    swiperElements.forEach((swiperElement) => {
      swipers.push(
        new Swiper(swiperElement, {
          modules: [Pagination, Navigation],

          navigation: {
            prevEl:
              swiperElement.querySelector<HTMLButtonElement>(
                '.swiper-btn-prev',
              ),
            nextEl:
              swiperElement.querySelector<HTMLButtonElement>(
                '.swiper-btn-next',
              ),
          },

          pagination: {
            el: swiperElement.querySelector<HTMLButtonElement>(
              '.swiper-pagination',
            ),
            clickable: true,
          },

          loop: true,
          slidesPerView: 1,
          spaceBetween: 20,
        }),
      );
    });
  },
  () => {
    while (swipers.length > 0) {
      swipers.pop()?.destroy(true, true);
    }
  },
);
