import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule } from '../utils/helpers';

const OVERLAP_OFFSET = 30;

const getElements = () => ({
  overlapContainers: document.querySelectorAll<HTMLElement>(
    '.content-block--overlap .container',
  ),
});

const setupOverlapContainers = () => {
  const { overlapContainers } = getElements();
  if (!overlapContainers) return;

  overlapContainers.forEach((container) =>
    overlapResizeObserver.observe(container),
  );
};
const cleanupOverlapContainers = () => {
  overlapResizeObserver.disconnect();
};

const overlapContainerBreakpointChecker = () => {
  if (BREAKPOINT_MEDIA_QUERIES.md.matches) {
    setupOverlapContainers();
    return;
  }

  cleanupOverlapContainers();
};

const overlapResizeObserver = new ResizeObserver((entries) => {
  entries.forEach((entry) => {
    const contentBlock = entry.target.querySelector<HTMLElement>('.content')!;
    const overlapBlock =
      entry.target.querySelector<HTMLElement>('[data-overlap]')!;

    const contentHeight = contentBlock.scrollHeight;
    const overlapHeight = overlapBlock.scrollHeight;

    const isOverlap = overlapHeight > contentHeight + OVERLAP_OFFSET;

    overlapBlock.dataset.overlap = String(isOverlap);
  });
});

export default defineModule(
  async () => {
    overlapContainerBreakpointChecker();
    BREAKPOINT_MEDIA_QUERIES.md.addEventListener(
      'change',
      overlapContainerBreakpointChecker,
    );
  },
  () => {
    cleanupOverlapContainers();
    BREAKPOINT_MEDIA_QUERIES.md.removeEventListener(
      'change',
      overlapContainerBreakpointChecker,
    );
  },
);
